var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      class: { show: _vm.shown },
      staticStyle: { display: "block", "padding-right": "15px" },
      style: _vm.shown
        ? "display: block; padding-right: 15px"
        : "display: none",
      attrs: {
        id: "basicExampleModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalLabel",
      },
    },
    [_c("div", { staticClass: "loader" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }