<template>
  <div class="modal fade" :class="{'show': shown}" id="basicExampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="display: block; padding-right: 15px;" :style="shown?'display: block; padding-right: 15px':'display: none'">
        <div class="loader"></div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    'loaded': {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      shown: 'ui/loader/shown',
    }),
  },
  mounted() {
    if (this.loaded) {
      this.$store.dispatch("ui/loader/show")
    }
  }
}
</script>

<style scoped>
  .loader {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }
</style>